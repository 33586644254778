.ng-sidebar__content {
    display: none;
}

.side-nav {
    max-width: 250px;
    width: 100%;

    .main-menu {
        height: calc(100vh - 70px);
        overflow-y: scroll;
        overflow-x: hidden;
    }

    li {
        display: flex;
        justify-content: space-between;
        color: black !important;
        text-decoration: none !important;
        margin: 10px auto;
        i {
            margin-right: 15px;
            font-weight: bold;
        }
    }
    ul.parent {
        width: 100%;
        margin: 0;
        padding: 0;
        i {
            font-size: 16px;
        }
        li.parent {
            display: flex;
            align-items: space-between;
            color: black !important;
            text-decoration: none !important;
            margin: 0px auto;
            justify-content: center;
            cursor: pointer;
            flex-direction: column;
            white-space: nowrap;
            &:hover {
                background-color: #e5e5e5;
                color: #185992 !important;
                transition: 0.3s;
            }
            a {
                display: flex;
                align-items: center;
                text-decoration: none;
                height: 100%;
                width: 100%;
                padding: 0 15px;
                min-height: 50px;
            }
        }
        .child-menu {
            overflow: hidden;
        }

        ul.child {
            width: 100%;
            margin: 0;
            padding: 0;
            li {
                display: flex;
                align-items: center;
                text-decoration: none !important;
                margin: 0px auto;
                justify-content: space-between;
                flex-direction: column;
                cursor: pointer;
                white-space: nowrap;
                &:hover {
                    background-color: rgba(0, 0, 0, 0.05);
                    color: #185992 !important;
                }
                ul.grandchild {
                    width: 100%;
                    margin: 0;
                    padding: 0;
                    li {
                        display: flex;
                        align-items: center;
                        text-decoration: none !important;
                        margin: 0 auto;
                        justify-content: space-between;
                        cursor: pointer;
                        white-space: nowrap;
                        &:hover {
                            background-color: rgba(0, 0, 0, 0.05);
                            color: #185992 !important;
                        }
                        a {
                            display: flex;
                            align-items: center;
                            text-decoration: none;
                            height: 100%;
                            width: 100%;
                            padding: 0 25px;
                            min-height: 50px;
                        }
                    }
                }
            }
            a {
                display: flex;
                align-items: center;
                text-decoration: none;
                height: 100%;
                width: 100%;
                padding: 0 15px;
                min-height: 50px;
            }
        }
    }

    .nav-text {
        width: calc(100% - 47px);
    }
}

.closed {
    overflow: hidden;
    max-height: 0;
}

.animated-open {
    max-height: none;
    animation-name: slide-down;
    animation-duration: 0.7s;
}

.animated-close {
    max-height: 0;
    animation-name: slide-up;
    animation-duration: 0.3s;
}

.selected {
    background-color: #e5e5e5;
    li {
        background-color: #e5e5e51f;
    }
}

li.parent.route,
li.child.route,
li.grandchild.route {
    .nav-text,
    i {
        color: #185992;
    }
}

li.parent.selected,
li.child.selected {
    .nav-text {
        color: #185992;
    }
}

li.child,
li.grandchild {
    :hover {
        color: #185992;
    }
    a {
        color: black;
    }
    i {
        margin-right: 10px;
    }
}

.label {
    width: calc(100% - 42px);
}

.sign-out {
    :hover {
        .label,
        i {
            color: #185992;
        }
    }
    .label,
    i {
        color: black;
    }
}

.badge-number {
    background-color: #185992;
    color: white;
    font-size: 15px;
}

.side-nav .badge {
    display: inline-block;
    padding: 0.3em 0.5em;
    font-size: 75%;
    font-weight: bold;
    line-height: 1;
    color: #fff !important;
    text-align: center;
    white-space: nowrap;
    vertical-align: baseline;
    margin-right: 6px;
    border-radius: 0.25rem;

    // Empty badges collapse automatically
    &:empty {
        display: none;
    }
}

.ng-sidebar {
    max-width: 250px;
    width: 100%;
    box-shadow: 1px 0 2px rgba(0, 0, 0, 0.15);
    position: fixed !important;
    background-color: #FCFCFC;
}

.ng-sidebar--docked {
    max-width: 60px;
    .badge {
        position: absolute;
        right: 1px;
    }
}

.hover {
    max-width: 250px;
    .pi-angle-down,
    .pi-angle-up,
    .label,
    .nav-text {
        display: block !important;
    }
}

.hover.docked-side .ng-sidebar {
    animation-name: slide-side-right;
    animation-duration: 0.3s;
    z-index: 9999;
    li {
        overflow: hidden;
    }
}

::-webkit-scrollbar {
    width: 3px;
}

::-webkit-scrollbar-thumb {
    background: lightgray;
    max-height: 200px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
    background: lightgray;
}

.animate-checked {
    .ng-sidebar {
        animation-name: slide-side-right;
        animation-duration: 0.3s;
    }
}

.sidenav-footer {
    position: absolute;
    bottom: 0;
    width: 100%;
    background-color: #fafafa;
    border-top: 1px solid rgba(0, 0, 0, 0.1);
    a {
        text-decoration: none;
        color: black;
    }
    li:hover a {
        color: #185992;
    }
    li {
        background: transparent !important;
    }
}

@media screen and (min-width: 992px) {
    .docked .pi-angle-down,
    .docked .pi-angle-up,
    .docked .label,
    .docked .nav-text {
        display: none;
    }
}

@media screen and (max-width: 992px) {
    .ng-sidebar {
        margin-top: 60px;
        .side-nav {
            min-height: calc(100vh - 120px);
        }
    }
}
