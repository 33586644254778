@font-face {
    font-family: "Roboto";
    src: url("Roboto-LightItalic.woff") format("woff");
    font-weight: 300;
    font-style: italic;
}

@font-face {
    font-family: "Roboto";
    src: url("Roboto-ThinItalic.woff") format("woff");
    font-weight: 100;
    font-style: italic;
}

@font-face {
    font-family: "Roboto";
    src: url("Roboto-Light.woff") format("woff");
    font-weight: 300;
    font-style: normal;
}

@font-face {
    font-family: "Roboto";
    src: url("Roboto-Medium.woff") format("woff");
    font-weight: 500;
    font-style: normal;
}

@font-face {
    font-family: "Roboto";
    src: url("Roboto-Black.woff") format("woff");
    font-weight: 900;
    font-style: normal;
}

@font-face {
    font-family: "Roboto";
    src: url("Roboto-Italic.woff") format("woff");
    font-weight: normal;
    font-style: italic;
}

@font-face {
    font-family: "Roboto";
    src: url("Roboto-BoldItalic.woff") format("woff");
    font-weight: bold;
    font-style: italic;
}

@font-face {
    font-family: "Roboto";
    src: url("Roboto-Thin.woff") format("woff");
    font-weight: 100;
    font-style: normal;
}

@font-face {
    font-family: "Roboto";
    src: url("Roboto-MediumItalic.woff") format("woff");
    font-weight: 500;
    font-style: italic;
}

@font-face {
    font-family: "Roboto";
    src: url("Roboto-BlackItalic.woff") format("woff");
    font-weight: 900;
    font-style: italic;
}

@font-face {
    font-family: "Roboto";
    src: url("Roboto-Bold.woff") format("woff");
    font-weight: bold;
    font-style: normal;
}

@font-face {
    font-family: "Roboto";
    src: url("Roboto-Regular.woff") format("woff");
    font-weight: normal;
    font-style: normal;
}
