@keyframes slide-down {
    0% {
        max-height: 0;
    }
    100% {
        max-height: 100vh;
    }
}

@keyframes slide-up {
    0% {
        max-height: 100vh;
    }
    100% {
        max-height: 0px;
    }
}

@keyframes slide-right {
    0% {
        width: calc(100% - 60px);
        margin-left: 60px;
    }
    100% {
        width: calc(100% - 250px);
        margin-left: 250px;
    }
}

@keyframes slide-left {
    0% {
        width: calc(100% - 250px);
        margin-left: 250px;
    }
    100% {
        width: calc(100% - 60px);
        margin-left: 60px;
    }
}

@keyframes slide-side-right {
    0% {
        width: 60px;
    }
    100% {
        width: 250px;
    }
}

@keyframes slide-left-mobile {
    0% {
        width: calc(100% - 250px);
        margin-left: 250px;
    }
    100% {
        width: 100%;
        margin-left: 0;
    }
}

@keyframes rotate-open {
    0% {
        transform: rotate(0);
    }
    100% {
        transform: rotate(90deg);
    }
}

@keyframes rotate-close {
    0% {
        transform: rotate(90deg);
    }
    100% {
        transform: rotate(0);
    }
}


@keyframes pulse {
    0% { box-shadow: 0 0 0 0 var(--hover); }
}

@keyframes blink-animation {
    to {
        visibility: hidden;
    }
}
