/* You can add global styles to this file, and also import other style files */
@import "assets/styles/keyframes.scss";
@import "assets/styles/sidenav.scss";
@import "assets/fonts/roboto/stylesheet.css";

@import url('https://fonts.googleapis.com/css2?family=Quicksand:wght@300;400;500;600;700&display=swap');

html,
body {
    height: 100%;
}

body {
    width: 100%;
    margin: 0;
    font-size: 14px;
    letter-spacing: 0.3px;
    font-family: Roboto, "Helvetica Neue", sans-serif;
}

.swal2-container {
    z-index: 10000000 !important;
}

button:disabled{
    background: rgba(0, 0, 0, 0.12) !important;
    &:hover{
        background-color: rgba(0, 0, 0, 0.2);
    }
}

.easy-blue {
  color: #199BF1;
}

button,
.fab-button,
.badge-pill{
    background: white;
    &.danger {
        background-color: #ef5350 !important;
        color: white !important;
        &:hover{
            background: #f13430 radial-gradient(circle, transparent 1%, #f13430 1%) center/15000% !important;
        }
        &:disabled{
            background-color: #ef5350 !important;
            color: white !important;
            opacity: 0.65 !important;
        }
    }
    &.primary {
        background-color: #2196f3 !important;
        color: white !important;
        &:hover{
            background: #47a7f5 radial-gradient(circle, transparent 1%, #47a7f5 1%) center/15000% !important;
        }
        &:disabled{
            background-color: #2196f3 !important;
            color: white !important;
            opacity: 0.65 !important;
        }
    }
}

p-dropdown,
p-multiSelect {
    &.danger .p-dropdown{
        background-color: #e96d6b;
        .p-dropdown-label.p-placeholder{
            color: white;
        }
    }
}

.success {
    background-color: #49a54e;
    color: white !important;
    &:hover{
        background: #16a51d radial-gradient(circle, transparent 1%, #16a51d 1%) center/15000%;
    }
    &:disabled{
        background-color: #49a54e !important;
        color: white;
        opacity: 0.65;
    }
}

.document-success {
    background-color: #49a54e;
    color: white;
    padding: 2px 10px;
    border-radius: 5px;
}

.document-danger{
    background-color: #ef5350;
    color: white;
    padding: 2px 10px;
    border-radius: 5px;
}

.document-warning {
    background-color: #e8af00;
    color: white;
    padding: 2px 10px;
    border-radius: 5px;
}

.document-processing {
    background-color: #47a7f5;
    color: white;
    padding: 2px 10px;
    border-radius: 5px;
}

button.easymarine {
    height: 36px;
    border: none;
    border-radius: 2px;
    min-width: 88px;
    line-height: 36px;
    box-shadow: inset 0px 0px 8px rgba(0, 0, 0, 0.12), 0px 8px 8px rgba(0, 0, 0, 0.24);
    padding: 0 15px;
    letter-spacing: 0.7px;
    font-size: 14px;
    font-family: Roboto, sans-serif;
    cursor: pointer;
}

:focus {
    outline: 0;
}

.round-checkbox {
    appearance: radio;
    width: 1.2em;
    height: 1.2em;
    border-radius: 50%;
    vertical-align: middle;
    // border: 2px solid white;
    -webkit-appearance: none;
    cursor: pointer;
    background-color: #185992;
    box-shadow: 0 0 0 2px white;
}

.round-checkbox.checked {
    // border: 2px solid #185992;
    background-color: white;
    border: 2px solid #185992;
    box-shadow: 0 0 0 2px white;
}

.p-float-label input:focus ~ label,
.p-float-label input.p-filled ~ label,
.p-float-label textarea:focus ~ label,
.p-float-label textarea.p-filled ~ label,
.p-float-label .p-inputwrapper-focus ~ label,
.p-float-label .p-inputwrapper-filled ~ label {
    top: 0 !important;
    font-size: 12px !important;
}

button.full-width {
    width: 100%;
}

.easy-button {
    font-family: Roboto, "Helvetica Neue", sans-serif !important;
    font-size: 14px !important;
    font-weight: 500 !important;
}

.alert-danger {
    background-color: #f2dede;
    border-color: #ebcccc !important;
    color: #a94442;
    white-space: normal !important;
}

.alert-warning {
    background-color: #fcf8e3;
    border-color: #f8f0ce !important;
    color: #8a6d3b;
    white-space: normal !important;
}

.alert-success {
    background-color: #dff0d8;
    border-color: #d4f0c9 !important;
    color: #3c763d;
    white-space: normal !important;
}

.alert {
    padding: 0.75rem 1.25rem;
    margin-bottom: 1rem;
    border: 1px solid transparent;
    border-radius: 0.2rem;
    font-size: 15px !important;
}

@media screen and (min-width: 640px){
    .text-center {
        text-align: center !important;
        &.nowrap{
            text-wrap: nowrap !important;
        }
    }
    .text-right {
        text-align: right !important;
    }
}


.p-tooltip {
    font-size: 13px !important;
    max-width: 280px !important;
}

.page {
    margin-top: 60px;
    margin-left: 60px;
    padding: 10px;
    height: calc(100vh - 110px);
    overflow-y: scroll;
    position: fixed;
    width: calc(100% - 60px);
}

.page.checked {
    margin-left: 250px;
    width: calc(100% - 250px);
}

.fab-button {
    width: 40px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    box-shadow: 0 3px 5px -1px rgba(0, 0, 0, 0.2), 0 6px 10px 0 rgba(0, 0, 0, 0.14), 0 1px 18px 0 rgba(0, 0, 0, 0.12);
    border: 0;
    cursor: pointer;
    color: white;
    &.primary{
        background-color: #2196f3;
    }
    &.danger{
        background-color: #ef5350;
    }
}

.page-header {
    display: flex;
    width: calc(100% - 30px);
    position: absolute;
    align-items: center;
    background: white;
    border: 1px solid rgb(207, 206, 206);
    margin-top: -80px;
    margin-left: 15px;
    padding: 0 15px;

    .page-title {
        font-size: 19px;
        font-weight: normal;
        line-height: 1;
        flex: 6;
        color: #185992;
    }

    .page-actions {
        flex: 1;
        display: flex;
        justify-content: flex-end;
    }
}

.flex-row {
    display: flex;
    flex-direction: row;
}

.p-datepicker {
    select {
        border: 0;
    }
    table {
        max-width: 200px !important;
        display: flex;
        flex-direction: column;
        margin: 17px auto !important;
        font-size: 13px;
        tr {
            display: flex;
            th,
            td {
                padding: 0.9px;
                display: flex;
                flex: 1;
                max-height: 35px;
                max-width: 14%;
                font-size: 13px;
                align-items: center;
                justify-content: center;
                span {
                    width: 35px;
                    height: 25px;
                    margin: 0;
                }
            }
        }
    }
}

.container-fluid {
    margin: 80px 25px 60px 25px;
    display: contents;
    i {
        &.success{
            color: green !important;
            background-color: transparent !important;
        }
        &.danger{
            color: red;
        }
        &.primary{
            color: #2196f3;
        }
    }

    .header-buttons{
        max-width: 50%;
        display: flex;
        align-items: center;
        justify-content: flex-end;
        .fab-button {
            margin: 4px;
            padding: 15px;
        }
    }

    .new-header-buttons{
        max-width: 50%;
        display: flex;
        align-items: center;
        justify-content: flex-end;
        button,
        .fab-button {
            margin: 4px;
            margin-right: 16px;
            box-shadow: none;
        }
        button,
        .fab-button,
        .badge-pill{
            background: white;
            &.danger {
                background-color: #ef5350 !important;
                color: white !important;
                &:hover{
                    background: #f13430 radial-gradient(circle, transparent 1%, #f13430 1%) center/15000% !important;
                }
                &:disabled{
                    background-color: #ef5350 !important;
                    color: white !important;
                    opacity: 0.65 !important;
                }
            }
            &.primary {
                background-color: #2573B8 !important;
                color: white !important;
                &:hover{
                    background: #47a7f5 radial-gradient(circle, transparent 1%, #47a7f5 1%) center/15000% !important;
                }
                &:disabled{
                    background-color: #2573B8 !important;
                    color: white !important;
                    opacity: 0.65 !important;
                }
                &:active,
                &:focus{
                    background-color: #2573B8 !important;
                    color: white !important;
                }
            }
        }
    }

    .p-fieldset .p-fieldset-legend {
        width: 100%;
        height: 54px;
        .article-title {
            display: flex;
            justify-content: space-between;
            align-items: center;
            height: 21px;
            font-size: 1.1em;
            text-transform: uppercase;
            font-weight: bold;
        }
    }

    .p-calendar,
    .p-dropdown,
    .p-multiselect,
    .p-autocomplete {
        max-height: 30px;
        display: flex;
    }
    .p-dropdown-label,
    .p-multiselect-label {
        padding: 0 10px;
        height: 30px;
        display: flex;
        align-items: center;
    }

    .p-inputtext {
        max-height: 30px;
    }

    .filters {
        display: flex;
        transition: max-height 1s;
        width: 100%;
        flex-direction: column;
        max-height: 0;
        overflow: hidden;
        .filter-row {
            display: flex;
            flex-wrap: wrap;
            white-space: nowrap;
            margin: 10px 0;
            .form-control {
                flex: 1;
                margin: 0 7px 9px;
                display: flex;
                flex-direction: column;
                min-width: 200px;
                max-width: 350px;
                label {
                    margin-bottom: 7px;
                }
            }
        }
        .buttons {
            flex: 1;
            display: flex;
            justify-content: flex-end;
            align-items: center;
            .form-button {
                margin: 0 7px;
                button {
                    padding: 0 15px;
                    letter-spacing: 0.7px;
                    color: #f4f4f4;
                    font-size: 14px;
                    font-family: Roboto, sans-serif;
                    text-transform: uppercase;
                    margin-top: 16px;
                }
            }
            .new-form-button {
                margin: 7px 7px;
                i {
                    align-items: center !important;
                    vertical-align: middle !important;
                }
                button {
                    align-items: center !important;
                    vertical-align: middle !important;
                    letter-spacing: 0.7px;
                    color: #f4f4f4;
                    font-size: 14px;
                    font-family: Roboto, sans-serif;
                }
            }
        }
        &.active{
            max-height: 100vh;
        }
    }

    .table {
        .table-actions{
            width: 100%;
            display: flex;
            justify-content: space-between;
        }
        .table-action {
            cursor: pointer;
            width: fit-content;
            margin: 0 7px;
            display: inline-block;
        }
    }

    .table-icon {
        font-size: 22px;
    }

    button.table-icon{
        background: transparent !important;
        border: 0;
        border-radius: 3px;
        cursor: pointer;

        .regular{
            color: #2196f3;
            &:hover{
                color: #47a7f5 !important;
            }
        }

        .danger{
            color: #ef5350;
            &:hover{
                color: #f13430 !important;
            }
        }

        .success{
            color: #49a54e;
            &:hover{
                color: #16a51d !important;
            }
        }
    }

    .table-image {
        height: auto;
        width: 35px;
        padding: 7px 3px;
    }

    .badge-pill {
        padding-right: 0.6em;
        padding-left: 0.6em;
        border-radius: 10rem;
        text-decoration: none;
        cursor: pointer;
        font-size: 13px;
        padding: 7px 13px 7px 13px;
    }

    .field-error{
        color: red;
        margin-top: 8px;
    }
}

.flex-start {
    justify-content: flex-start !important;
}

.space-around {
    justify-content: space-around !important;
}

.mat-dialog-container {
    padding: 0 !important;
}

.dialog-form {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    padding: 15px;
    .form-group {
        display: flex;
        align-items: flex-start;
        .date-wash-turn {
            flex: 1;
            display: flex;
            flex-direction: column;
        }
        .option {
            flex: 2;
            display: flex;
            flex-direction: column;
            align-items: center;
            .radio-buttons {
                flex: 1;
                display: flex;
            }
        }
        p-fieldset{
            flex: 1;
        }
    }
    .buttons {
        flex: 1;
        display: flex;
        justify-content: flex-end;
        align-items: center;
        height: 51px;
        margin-top: 10px;
        button {
            margin: 0 7px;
        }
    }
    .form-control {
        flex: 1;
        margin: 0 5px;
        display: flex;
        flex-direction: column;
        min-width: 200px;
        max-width: 350px;
        &.quarter{
            min-width: 75px;
            max-width: 100px;
        }
        &.half{
            min-width: 100px;
            max-width: 175px;
        }
        &.full{
            width: 100%;
            min-width: auto;
            max-width: unset;
        }
        &.radio-buttons {
            min-width: 330px;
            max-width: 350px;
            margin-right: 10px;
            .form-group{
                justify-content: space-between;
                .p-checkbox-label {
                    margin-right: 5px;
                }
            }
        }
    }
    .p-datepicker {
        select {
            border: 0;
        }
        table {
            max-width: 200px !important;
            display: flex;
            flex-direction: column;
            margin: 17px auto;
            font-size: 13px;
            tr {
                display: flex;
                th,
                td {
                    padding: 0.9px;
                    display: flex;
                    flex: 1;
                    max-height: 35px;
                    max-width: 14%;
                    font-size: 13px;
                    align-items: center;
                    justify-content: center;
                    span {
                        width: 35px;
                        height: 25px;
                        margin: 0;
                    }
                }
            }
        }
    }
    label + input,
    label + p-dropdown,
    label + app-passengers,
    label + app-location,
    label + p-multiSelect,
    label + textarea,
    label + p-autoComplete,
    label + p-inputNumber .p-inputnumber,
    label + p-inputMask,
    label + p-listbox,
    label + p-fileUpload,
    label + .radio-buttons,
    label + .form-group,
    label + p-calendar .p-calendar{
        margin-top: 8px !important;
    }
    label + p-inputswitch{
        margin-top: 5px !important;
    }
    label + p-editor{
        margin-top: 5px !important;
        width: 100%;
        min-width: auto;
        max-width: unset;
    }
    .p-calendar,
    .p-dropdown,
    .p-inputnumber,
    .p-inputmask,
    .p-multiselect,
    .p-autocomplete {
        max-height: 30px;
        width: 100%;
        input{
            width: 100%;
        }
        &.readonly{
            background: #80808061;
            input{
                color: gray;
            }
            &:hover{
                border-color: #80808061;
            }
        }
    }
    .basic textarea.p-inputtext{
        max-height: 100% !important;
    }
    .p-inputtext {
        max-height: 30px;
        padding: 0.2rem 0.5rem;
    }
    .p-button.p-button-icon-only{
        padding: 0 !important;
    }
    .table{
        height: calc(100% - 60px);
        overflow-y: scroll;
        .table-action {
            cursor: pointer;
            width: fit-content;
            margin: 0 auto;
        }
    }
}

.p-calendar {
    align-items: baseline;
    button {
        align-self: stretch;
    }
}

.p-dropdown-filter-container {
    position: relative;
    display: flex;
    align-items: center;
}

.mat-dialog-container {
    padding: 0 !important;
    p-header {
        display: flex;
        flex-direction: row;
        align-items: center;
        width: 100%;
        justify-content: space-between;
        &.mat-header {
            padding: 14px;
            color: white;
            background: steelblue;
        }
        h3 {
            margin: 0;
            font-weight: bold;
            font-size: large;
        }
        i {
            cursor: pointer;
            font-size: large;
            font-weight: bold;
        }
        i:hover {
            color: #ced4da;
        }
    }
    button {
        padding: 0 15px;
        letter-spacing: 0.7px;
        color: #f4f4f4;
        font-size: 14px;
        font-family: Roboto, sans-serif;
        text-transform: uppercase;
    }
}

.p-dialog {
    .p-dialog-content {
        height: 100%;
        padding: 0 !important;
    }
    .p-dialog-header {
        background-color: #185992 !important;
        color: white !important;
        .p-dialog-header-icon {
            .p-dialog-header-close-icon {
                color: white;
            }
            &:hover {
                .p-dialog-header-close-icon {
                    color: #185992;
                }
            }
        }
    }
    .p-panel-header {
        display: flex;
        flex-direction: row;
        align-items: center;
        width: 100%;
        justify-content: space-between;
        h3 {
            margin: 0;
        }
        i {
            cursor: pointer;
            font-size: large;
            font-weight: bold;
            position: fixed;
            top: 20px;
            right: 20px;
        }
        i:hover {
            color: #ced4da;
        }
    }
    button {
        padding: 0 15px;
        letter-spacing: 0.7px;
        color: #f4f4f4;
        font-size: 14px;
        font-family: Roboto, sans-serif;
        text-transform: uppercase;
        &.easymarine{
            color: #495057;
        }
    }
    .p-calendar-w-btn button{
        margin-top: 0px !important;
    }
}

.sling-table {
    .p-datatable-wrapper{
        max-height: calc(100vh - 325px);
        overflow-x: hidden !important;
    }
    .p-datatable-thead{
        tr {
            display: flex;
            th {
                position: -webkit-sticky;
                position: sticky;
                top: 0;
                border-bottom: 2px solid darkgray !important;
                border-width: 0px 1px 2px 0px !important;
                z-index: 1000;
                min-width: 70px;
                flex: 1;
                border: 0 !important;
                td {
                    padding: 0.5rem 0.5rem !important;
                }
            }
        }
    }
    .p-datatable-tbody{
        display: flex;
        flex-direction: column;
        tr{
            display: flex;
            justify-content: space-around;
        }
        td{
            flex: 1;
            display: flex;
            align-items: center;
            justify-content: center;
            min-width: 70px;
            img {
                max-height: 45px;
                max-width: 45px;
                width: auto;
                height: auto;
            }
        }
    }
    .sling-time-mobile{
        display: none;
    }
}

.easy-table{
    &.p-datatable {
        border: 1px solid lightgray;
        box-shadow: 4px 5px 10px lightgrey;
        .p-datatable-header {
            // text-align: center;
            display: flex;
            justify-content: space-between;
            align-items: center;
            white-space: nowrap;
            flex-wrap: wrap;
            .table-header{
                display: flex;
                min-width: 100%;
                justify-content: space-between;
                margin: 5px auto;
                &.center{
                    justify-content: center;
                }
            }
            &.center{
                justify-content: center !important;
            }
        }
    }
    &.center-header .p-datatable-header{
        justify-content: center !important;
    }
    &.flexend-header .p-datatable-header{
        justify-content: flex-end !important;
    }
    .p-datatable-thead > tr > th {
        padding: 5px !important;
    }
}

.easy-small-table{
    .p-datatable-thead > tr > th {
        padding: 5px !important;
    }
}

.sling-check.p-tabview {
    background: #e1e8f5;
    .p-tabview-nav {
        background: transparent;
        li {
            .p-tabview-nav-link {
                background: transparent;
            }
        }
    }
    .p-tabview-panels {
        background: transparent;
    }
}

.dialog-form .pi.pi-search.right {
    font-size: 1.2em;
    font-weight: bold;
    margin: 0 6px;
    position: relative;
    top: 2px;
}

.menu-item-level1-notification i {
  font-size: 0.5rem !important;
  color: #185992;
}

.p-datatable .p-sortable-column .p-sortable-column-icon {
    margin-left: 0 !important;
}

.p-datatable-wrapper {
    font-size: 15px;
}

.prime-table {
    .p-datatable-thead > tr > th {
        padding: 6px 9px !important;
        border-width: 0px 1px 1px 0px !important;
        border: 0 !important;
    }
}

.p-fieldset-content {
    font-size: 14px !important;
}

.p-fileupload{
    max-height: 36px;
    border: none;
    border-radius: 2px;
    min-width: 88px;
    box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.12), 0px 8px 8px rgba(0, 0, 0, 0.24);
    margin: 0 5px;
    max-width: fit-content;
    white-space: nowrap;
    .certificate{
        max-height: 30px;
        .p-button-icon-left,
        .p-button-label{
            font-size: 12px;
        }
    }

    :hover{
        background: #47a7f5 radial-gradient(circle, transparent 1%, #47a7f5 1%) center/15000% !important;
        border-color: #47a7f5 !important;
    }
    .p-button{
        background-color: #2196f3;
        border-radius: 2px;
        padding: 8.5px 16px;
        letter-spacing: 0.7px;
        color: #f4f4f4;
        font-size: 14px;
        font-family: Roboto, sans-serif;
        .p-button-label{
            background: transparent !important;
        }
        :hover{
            background: #47a7f5 radial-gradient(circle, transparent 1%, #47a7f5 1%) center/15000% !important;
            border-color: #47a7f5 !important;
        }
    }
}

.p-fileupload-mini{
  max-height: 36px;
  border: none;
  border-radius: 2px;
  box-shadow: none !important;
  margin: 0px;
  max-width: fit-content;
  white-space: nowrap;

  .p-fileupload{
    box-shadow: none;
    min-width: 50px;
    border: none;
    border-radius: 0px;
    margin: 0pt;

  }
  .certificate{
      max-height: 30px;
      .p-button-icon-left,
      .p-button-label{
          display: none;
          font-size: 12px;
      }
  }

  :hover{
      background: transparent !important;
      border-color: transparent !important;
      color: #000000;

  }
  .p-button{
      background-color: transparent;
      border-radius:0px;
      border: none;
      padding: 8.5px 5px 8.5px 0px;
      letter-spacing: 0.7px;
      color: black;
      font-size: 0px;
      font-family: Roboto, sans-serif;
      .p-button-label{
          background: transparent !important;
          display: none;
      }
      :hover{
          background: transparent  !important;
          border-color: transparent !important;
          color: #000000;
      }
  }
}

p-fileupload.danger {
    .p-button,
    .p-button-label,
    .p-button-icon{
        background-color: #ef5350;
        border: 0 !important;
        &:hover{
            background: #f13430 radial-gradient(circle, transparent 1%, #f13430 1%) center/15000% !important;
        }
    }
}


.error .p-checkbox-box.p-highlight{
    border: 1px solid #cc0b14 !important;
    background: red !important;
}

.sling-table {
    .badge {
        display: inline-block;
        padding: 3px;
        font-size: 14px;
        font-weight: bold;
        line-height: 1;
        width: 100%;
        text-align: center;
        white-space: nowrap;
        vertical-align: baseline;
        position: relative;
        border-radius: 8px;
        background-color: #ffa95e;
    }

    .fuel-image {
        flex: 1;
    }

    .flex-badge {
        flex: 1;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        min-height: 50px;
    }

    .badge.attempted {
        color: #4b4847 !important;
        background-color: transparent !important;
        border: solid 2px;
    }

    .badge.fuel-type {
        color: #fff;
        background-color: #3d3880;
    }

    .badge.consumable {
        background-color: #ad9257;
        color: #4b4847;
    }
}

.em-alert {
    color: #ab4142 !important;
}

.swal2-content {
    font-size: 16px !important;
}

.swal2-title{
    display: flex;
    flex-direction: column;
}

.sling-table {
    .sling-button {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: 100%;
        background: transparent;
        border: 0;
        box-shadow: none;
        cursor: pointer;
        img {
            max-height: 50px;
            width: auto;
        }
    }

    .icon-button {
        cursor: pointer;
        display: flex;
        align-items: center;
        img {
            max-height: 45px;
            width: auto;
        }
    }

    .em-attempted {
        background-color: #a1d2af !important;
    }
    .em-prepared {
        background-color: lightblue !important;
    }
    .easy-warning-tr {
        background-color: #ffe698 !important;
    }


    .movement-prepared {
      background-color: #a1d2af !important;
      &:nth-child(odd) {
        background-color: #98cda7 !important;
      }
    }
    .movement-started {
      background-color: #ffe9a8 !important;
      &:nth-child(odd) {
        background-color: #ffe698 !important;
      }
    }
    .movement-few-slings {
      background-color: #4e9ed5 !important;
    }
}

button:disabled{
    background-color: rgba(0, 0, 0, 0.12);
    color: rgba(0, 0, 0, 0.26);
}

.sling-button {
    margin-left: 10px;
    background-color: #5cb85c;
    width: 40px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    box-shadow: 0px 0px 2px rgba(92, 184, 92, 0.12), 0px 2px 4px rgba(0, 0, 0, 0.24);
    cursor: pointer;
    &:hover,
    &:focus {
        text-decoration: none;
    }
}

.form-control .p-radiobutton-label {
    margin-right: 10px;
}

.p-datatable-header{
    padding: 0.5rem 1rem !important;
    text-align: center;
    tr{
        padding: 0.5rem 1rem !important;
    }
}

.icon-button{
    border: 0;
    cursor: pointer;
    background: transparent;
    color: black;
    &.primary i{
        color: #2196f3;
        font-size: 19px;
    }
    &:disabled{
        background: transparent !important;
        color: darkgrey;
    }
}

.no-border .p-panel-content{
    border: 0 !important;
}

.subtitles{
    display: flex;
    width: 100%;
    fieldset {
        flex: 1;
        border: 1px solid #d6dbe0;
        margin-bottom: 10px;
        padding-bottom: 5px;
    }
    .badges{
        display: flex;
        align-items: center;
        justify-content: flex-start;
        i{
            margin: 0 5px;
        }
        .badge{
            width: 20px;
            height: 20px;
            border-radius: 4px;
            margin: 0 5px;
        }
    }
}

.primeSubTable{
    tr, td{
        background: transparent !important;
        border-width: 0 !important;
    }
    .no-padding td{
        padding: 5px 0 !important;
    }
}


.p-datatable .no-padding td{
    padding: 5px 1rem !important;
}

.primeDialog {
    .p-dialog-content{
        min-height: 50vh;
        display: flex;
        align-items: center;
        justify-content: center;
        min-width: 320px;
        .dialog-form{
            height: 50vh;
            max-width: 280px;
            .form-title{
                text-align: center;
            }
            .form-control{
                margin: 0;
            }
        }
    }
}

.form-control.error {
    .p-dropdown, input[type=text]{
        background: lightcoral !important;
        color: #000000 !important;
    }
}

.p-datatable .p-datatable-tbody > tr > td{
    padding: 0.8rem 0.5rem !important;
}

.auto-width {
    .p-dropdown-items-wrapper{
        max-width: 150px;
        overflow-x: hidden;
        white-space: normal;
        .p-dropdown-item{
            white-space: normal;
        }
    }
}

.flex-end{
    align-items: flex-end;
}

.align-center{
    align-items: center;
}

.max-width.p-dropdown{
    max-width: 150px;
}

.boats .p-fieldset-legend{
    width: 100%;
    button i{
        color: white;
    }
}

.full .p-fieldset .p-fieldset-legend {
    width: 100%;
    height: 54px;
    .article-title {
        display: flex;
        justify-content: space-between;
        align-items: center;
        height: 21px;
    }
}

.full-width p-panel{
    width: 100%;
}

.dialog-form .primeSubTable .p-datatable {
    border: 1px solid lightgray;
    box-shadow: 4px 5px 10px lightgrey;
}

.blink {
    animation: blink-animation 1s steps(5, start) infinite;
    -webkit-animation: blink-animation 1s steps(5, start) infinite;
}

input:read-only{
    background: rgb(238, 238, 238);
}

p-table p-selectButton {
    .p-selectbutton{
        display: flex;
    }
    .p-button{
        max-height: 35px !important;
        &:focus{
            box-shadow: none;
        }
    }
}

.switch{
    min-width: 0 !important;
    max-width: 120px !important;
}

p-editor.required .p-editor-container {
    border: 1px solid #f44336 !important;
}

p-fieldset{
    &.flex1{
        flex: 1 !important;
    }
    &.flex2{
        flex: 2 !important;
    }
    &.flex0{
        display: none;
    }
}

p-inputMask{
    display: flex;
    padding: 0 !important;
}

p-inputNumber{
    &.correct .p-inputnumber-input{
        background-color: #66bb6a;
        color: white;
    }
    &.danger .p-inputnumber-input{
        background-color: lightcoral;
        color: white;
    }
    &.limited .p-inputnumber-input{
        max-width: 165px;
    }
    &.medium .p-inputnumber-input{
        max-width: 120px;
    }
    &.small .p-inputnumber-input{
        max-width: 80px;
    }
}

.p-inputtext {
    &.medium {
        max-width: 120px;
    }
    &.small {
        max-width: 80px;
    }
}

.auto-width{
    .p-inputnumber-input{
        width: 100%;
    }
}

.forecast-date .p-datepicker-trigger{
    border-bottom-right-radius: 0;
    border-top-right-radius: 0;
    border-right: 0;
}

#dropdown-icon-fav .p-dropdown-trigger{
    color: #FFDD22;
}

.radio-button{
    p-radioButton,
    p-dropdown,
    p-inputNumber{
        margin-left: 5px;
    }
    p-inputNumber{
        max-width: 80px;
    }
}

.destinations-dropdown {
    &.p-dropdown{
        min-height: 29.5px;
        width: 100%;
        border-bottom-left-radius: 0;
        border-top-left-radius: 0;
        border-left: 0;
        &.p-focus{
            box-shadow: none !important;
        }
        .p-dropdown-label{
            display: none;
        }
    }
}

.p-datatable-striped{
	.attempted-tr {
		background-color: #93ca8f !important;
		&:nth-child(even){
			background-color: #b9ddb6 !important;
		}
	}
}

.subtable{
    padding: 0 5px;
	.attempted-tr {
        &:nth-child(odd){
            background-color: #b9ddb6 !important;
		}
        &:nth-child(even){
            background-color: #93ca8f !important;
		}
	}
}

.fuel-box p-inputNumber {
    width: 100%;
    .p-inputnumber .p-inputnumber-input{
        max-width: 100%;
    }
}

.p-dynamic-dialog{
    .filters{
        display: flex;
        transition: max-height 1s;
        width: 100%;
        flex-direction: column;
        max-height: 0;
        overflow: hidden;
        .active{
            max-height: 100vh;
        }
    }
}

.content-log {
    display: flex;
    flex-wrap: wrap;

    div {
        flex: 1 0 200px;
        display: flex;
        flex-direction: column;
        margin-bottom: 20px;
        max-width: 200px;
        padding: 5px;
        label:first-child {
            font-weight: bold;
            margin-bottom: 10px;
        }

        label + label {
            border: 1px solid #ccc;
            border-radius: 5px;
            padding: 5px;
            margin-bottom: 5px;
            min-height: 31px;
        }
    }

    p{
        margin: 5px;
    }
}

.item-new-log {
    background-color: rgba(0, 129, 0, 0.336);
    td {
        background-color: rgba(0, 129, 0, 0.336);
    }
}

.item-old-log {
    background-color: rgba(255, 0, 0, 0.336);
    td {
        background-color: rgba(255, 0, 0, 0.336);
    }
}
.btn-csv-xls {
    background: #207245;
    color: #FFFFFF;
    display: flex;
	align-items: center;

    span {
        margin-right: 5px;
    }
}

.btn-pdf {
    background: #B10F0F;
    color: #FFFFFF;
    display: flex;
	align-items: center;

    span {
        margin-right: 5px;
    }
}

.invoice-change-date .p-calendar.p-calendar-w-btn {
  max-height: unset;
}

.input-destination-mv {
  width: 100%;
  box-sizing: border-box;
  &:focus{
      box-shadow: none;
  }
  .p-chips, .p-chips .p-chips-multiple-container
   {
    width: inherit !important;
    box-sizing: border-box !important;
    .p-chips-token {
      padding: 0 3px;
    }
  }
}

@media screen and (max-width: 526px){
    .p-dynamic-dialog{
        height: 100vh !important;
        max-height: 100vh !important;
    }
}

@media screen and (max-width: 1448px) and (min-width: 843px){
    .sling-time-mobile{
        display: block !important;
    }
    .sling-time{
        display: none;
    }
}

@media screen and (max-width: 925px){
    .sling-table{
        .p-datatable-thead{
            display: none;
        }
    }
}

@media screen and (max-width: 843px){
    .sling-table{
        .p-datatable-tbody{
            tr{
                flex-wrap: wrap;
                td{
                    min-width: 20%;
                    border: 0 !important;
                }
            }
        }
        .flex-badge{
            justify-content: space-around;
            .badge{
                font-size: 12px;
                padding: 2px;
                border-radius: 5px;
            }
        }
    }
}

@media screen and (max-width: 760px) and (min-width: 640px) {
    .table-action{
        display: block;
        margin: 0 auto;
    }
}

@media screen and (max-width: 700px){
    .p-datatable-header {
        justify-content: space-between;
        .fill-empty{
            display: none;
        }
        input{
            max-width: 200px;
        }
    }
}

@media screen and (max-width: 660px) and (min-width: 426px){
    .sling-table{
        .sling-time-mobile{
            display: block !important;
        }
        .sling-time{
            display: none;
        }
    }
}

@media screen and (max-width: 576px){
    .sling-table{
        .p-datatable-tbody{
            tr{
                td{
                    min-width: 25%;
                }
            }
        }
    }
}

@media screen and (min-width: 482px) and (max-width: 992px) {
    .page {
        margin-left: 0;
        width: 100%;
    }

    .form-control {
        margin: 8px !important;
    }
}

@media screen and (max-width: 526px){
    .form-control{
        min-width: 97% !important;
    }

    .buttons{
        justify-content: center !important;
    }
}

@media screen and (max-width: 512px){
    .p-tabview-nav{
        flex-wrap: nowrap !important;
        overflow-x: scroll;
        li{
            flex: 1;
            min-width: 42%;
        }
    }
}

@media screen and (max-width: 482px) {
    .page {
        margin-left: 0;
        width: 100%;
    }

    .form-control {
        max-width: 100% !important;
        margin: 8px auto !important;
    }

    .sling-table{
       .flex-badge{
           display: none !important;
       }
    }

}

@media screen and (max-width: 475px){
    .p-tabview .p-tabview-nav li .p-tabview-nav-link{
        padding: 10px !important;
        min-height: 65px;
    }
}

@media screen and (max-width: 470px){
    .p-datatable-header {
        justify-content: center !important;
    }
}

@media screen and (max-width: 40em){
    .p-datatable.p-datatable-responsive .p-datatable-tbody>tr>td {
        display: flex !important;
        align-items: center;
        justify-content: space-between;
        &.actions{
            justify-content: center !important;
        }
    }
}

@media screen and (max-width: 425px){
    .p-fieldset .p-fieldset-content{
        padding: 0 !important;
    }
    .sling-table{
        .p-datatable-tbody > tr > td{
            min-width: 50%;
            justify-content: center !important;
        }
        .p-column-title{
            text-align: center !important;
        }
    }
    .dialog-form{
        .confirm-operation{
            justify-content: center !important;
        }
        .buttons{
            flex-direction: column;
            align-items: center;
            button{
                margin: 10px auto;
            }
        }
    }
}

@media screen and (max-width: 380px){
    .p-datatable.p-datatable-responsive .p-datatable-tbody>tr>td{
        flex-direction: column;
        text-align: center !important;
        .p-column-title{
            margin: 0 !important;
        }
        &.actions{
            flex-direction: row !important;
        }
    }
}

.modal-res-movements {
  width: 96%;
  height: 80vh;
}

@media (min-width: 1200px){
  .modal-res-movements {
    width: 80%;
    height: 97vh !important;
    max-height: 100% !important;
  }
}

@media (min-width: 1600px){
  .modal-res-movements {
    width: 75%;
  }
}

@media (min-width: 1900px){
  .modal-res-movements {
    width: 65%;
  }
}

.easy-bank {
  .nix-bank {
    display: flex;
    justify-content: center;

    .min-box {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 18px;
      height: 10px;
      border-radius: 1.27px;
      font-size: 10px;
      font-weight: 700;
    }

    .med-box {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 24px;
      height: 14px;
      border-radius: 1.71px;
      font-size: 13px;
      font-weight: 700;
    }

    .big-box {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 30px;
      height: 17px;
      border-radius: 2.17px;
      font-size: 13.5px;
      font-weight: 700;
    }

    &--payed {
      background-color: #80C080;
    }

    &--registered {
      background-color: #1E74B9;
      color: #FFFFFF;
    }


    &--registered-pending {
      background-color: #FFE373;
    }

    &--refused  {
      background-color: #F24242;
    }

    &--awaiting-response {
      background-color: #00B7FF;
    }

    &--installments {
      background-color: #868686;
    }

    &--canceled {
      background-color: #C6C6C6;
    }

    &--more-then-one-billet {
      background-color: #B28EDE;
    }

    .font-color-white {
      color: #FFFFFF;
    }

    .font-color-black {
      color: #1F1F1F;
    }
  }
}

.inline-string {
    display: inline-block;
    white-space: nowrap;
  }


.custom-drawer {
    width: 380px;
    display: flex;
    flex-direction: column;
    border-style: solid;
    border-width: 1px;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
    right: 10px;
    position: absolute;
    z-index: 1000;
    padding: 4px 8px 24px 8px;
    background-color: white;
}

.drawer-header {
    color: black !important;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding: 0 8px;
    height: 36px;
    font-family: "Roboto", sans-serif;
    font-size: 18px;
    font-weight: 300;
}

.drawer-divider {
    height: 2px !important;
    background-color: #A1D6FF !important;
    margin: 8px 0 !important;
}

.drawer-body{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
}

.drawer-buttons {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    width: 40%;
}

.drawer-items {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    align-content: flex-start;
    width: 60%;
    padding-top: 8px;
}

.drawer-button {
    text-align: left;
    width: 100%;
    height: 46px;
    border: 0;
    border-bottom: 1px solid #A1D6FF;
    min-width: 88px;
    line-height: 36px;
    padding: 0 15px;
    letter-spacing: 0.7px;
    font-size: 16px;
    font-weight: 400;
    font-family: Roboto, sans-serif;
    cursor: pointer;
    white-space: nowrap;
}

.drawer-item {
    padding-left: 8px;
    padding-bottom: 24px;
    font-family: "Roboto", sans-serif;
    font-size: 14px;
    font-weight: 400;
}

.drawer-primary {
    background-color: #2196f3 !important;
    color: white !important;
    &:disabled{
        background-color: #2196f3 !important;
        color: white !important;
        opacity: 0.65 !important;
    }
    &:active{
        background: white !important;
        color: #2196f3 !important;
        font-weight: bold;
    }
}

.drawer-secondary {
    border: 0;
    font-size: 16px;
    font-weight: 400;
    font-family: Roboto, sans-serif;
    background-color: #2196f3 !important;
    color: white !important;
    width: 44px;
    height: 36px;
    border-radius: 5px;
    font-weight: bold;
    cursor: pointer;
}

.drawer-group-active {
    background: white !important;
    color: #2196f3 !important;
    font-weight: bold;
}

.drawer-footer {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 0 8px;
    padding-top: 42px;
    height: 36px;
    font-family: "Roboto", sans-serif;
    font-size: 14px;
    font-weight: 400;
    margin-bottom: auto;
}

.drawer-description {
    line-height: 36px;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    width: 80%;
    margin-bottom: 10px;
    border-bottom: 1px solid #A1D6FF;
}

.new-filters{
    display: flex;
    transition: max-height 1s;
    width: 100%;
    flex-direction: column;
    max-height: 0;
    overflow: hidden;
}

.new-filter-row {
    display: flex;
    flex-wrap: wrap;
    white-space: nowrap;
}

.new-form-control-md,
.new-form-control-sm,
.new-form-control-lg {
    flex: 1;
    margin: 6px 7px 9px;
    display: flex;
    flex-direction: column;
    &.no-form {
        margin: 0px 0px 0px 4px;
    }
}

.new-form-control-md {
    min-width: 30%;
    max-width: 35%;
}

.new-form-control-sm {
    min-width: 20%;
    max-width: 30%;
}

.new-form-control-lg {
    min-width: 40%;
    max-width: 50%;
}

.new-form-control-md label,
.new-form-control-sm label,
.new-form-control-lg label {
    margin-bottom: 7px;
}

.new-form-control-md .p-dropdown,
.new-form-control-md .p-multiselect,
.new-form-control-md .p-autocomplete,
.new-form-control-sm .p-dropdown,
.new-form-control-sm .p-multiselect,
.new-form-control-sm .p-autocomplete,
.new-form-control-lg .p-dropdown,
.new-form-control-lg .p-multiselect,
.new-form-control-lg .p-autocomplete {
    width: 100%;
    border: 1px solid #d6dbe0;
    border-radius: 2px;
    font-size: 14px;
    font-weight: 400;
    font-family: Roboto, sans-serif;
    color: #4b4847;
    &:focus {
        border-color: #2196f3;
        box-shadow: 0 0 0 0.2rem rgba(33, 150, 243, 0.25);
    }
    height: 48px;
    max-height: 48px;
    padding-top: 8px;
}

.new-form-control-md .p-calendar,
.new-form-control-sm .p-calendar,
.new-form-control-lg .p-calendar {
    width: 100%;
    border: 1px solid #d6dbe0;
    border-radius: 2px;
    font-size: 14px;
    font-weight: 400;
    font-family: Roboto, sans-serif;
    color: #4b4847;
    height: 48px;
    max-height: 48px;
}

.new-form-control-md .p-calendar .p-inputtext,
.new-form-control-sm .p-calendar .p-inputtext,
.new-form-control-lg .p-calendar .p-inputtext {
    margin-top: 8px;
    height: 48px;
    border: 0px;
    &:focus {
        border: 0px;
        box-shadow: none;
    }
    &:enabled {
        border: 0px;
        box-shadow: none;
    }
    &:enabled:focus {
        border: 0px;
        box-shadow: none;
    }
    &:hover {
        border: 0px;
        box-shadow: none;
    }
}

.new-form-control-md .p-calendar .p-button,
.new-form-control-sm .p-calendar .p-button,
.new-form-control-lg .p-calendar .p-button {
    font-size: 24px;
    background: white;
    color: black;
    background-color: white;
    border: 0px;
    &:focus {
        background: white;
        color: black;
        background-color: white;
        border: 0px;
    }
    &:enabled {
        background: white;
        color: black;
        background-color: white;
        border: 0px;
    }
    &:enabled:focus {
        background: white;
        color: black;
        background-color: white;
        border: 0px;
    }
    &:hover {
        background: white;
        color: black;
        background-color: white;
        border: 0px;
    }
}

.new-form-control-sm i {
    color: #2196f3 !important;
    font-size: 24px;
    margin-right: 4px;
    margin-top: -10px !important;
}

.new-input {
    width: 100%;
    border: 1px solid #d6dbe0;
    border-radius: 2px;
    color: #4b4847;
    &:focus {
        border-color: #2196f3;
        box-shadow: 0 0 0 0.2rem rgba(33, 150, 243, 0.25);
    }
    padding-left: 8px;
    line-height: 44px !important;
}

button.new-easymarine {
    height: 40px;
    border: none;
    border-radius: 4px;
    min-width: 78px;
    line-height: 36px;
    letter-spacing: 1px;
    font-size: 16px;
    font-family: Roboto, sans-serif;
    font-weight: 500;
    padding: 0 15px;
    cursor: pointer;
    &.disabled{
        background-color: #FFFFFF;
        border: 1px solid #a1a1a1;
        color: #2196f3;
    }
}

.new-easy-table{
    &.p-datatable {
        max-height: 50% !important;
        .p-datatable-header {
            display: flex;
            justify-content: space-between;
            align-items: center;
            white-space: nowrap;
            flex-wrap: wrap;
            border: none;

            .table-header{
                display: flex;
                min-width: 100%;
                justify-content: space-between;
                margin: 5px auto;
                &.center{
                    justify-content: center;
                }
            }
            &.center{
                justify-content: center !important;
            }
        }
    }
    &.center-header .p-datatable-header{
        justify-content: center !important;
    }
    &.flexend-header .p-datatable-header{
        justify-content: flex-end !important;
    }
}

.group-header {
    background: white !important;
    height: 36px;
    font-family: "Roboto", sans-serif;
    font-size: 18px;
    text-transform: uppercase;
    font-weight: 400 !important;
    white-space: nowrap;
    justify-content: space-between;
    &.even {
        color: #199BF1 !important;
        border-bottom: 2px solid #199BF1 !important;
    }
    &.odd {
        color: #49A54E !important;
        border-bottom: 2px solid #49A54E !important;
    }
    padding: 5px !important;
}

.group-header-spaces {
    background: white !important;
}

.group-header-items {
    th {
        padding: 5px !important;
        background: #dde6ee !important;
        border: none !important;
        &.green-border{
            border-left: 1px solid #49A54E !important;
        }
        &.blue-border{
            border-left: 1px solid #199BF1 !important;
        }
    }
    &.labels {
        white-space: nowrap;
    }
    &.totals {
        th {
            padding: 0 !important;
            padding-left: 5px !important;
            padding-right: 5px !important;
            font-size: 14px;
            font-weight: 400 !important;
        }
    }
}

.column-items {
    td {
        &.green-border{
            border-left: 1px solid #49A54E !important;
        }
        &.blue-border{
            border-left: 1px solid #199BF1 !important;
        }
        &.no-wrap{
            white-space: nowrap;
            i {
                color: #2094F0 !important;
            }
        }
    }
}

.remove-padding .p-fieldset .p-fieldset-content{
    padding-top: 0 !important;
    padding-bottom: 0 !important;
    margin-bottom: 10 !important;
}

.new-fab-button {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    border: 0;
    cursor: pointer;
    color: white;
    &.primary{
        background-color: #2196f3;
    }
    &.danger{
        background-color: #ef5350;
    }
    &.disabled{
        background-color: #FFFFFF;
        border: 1px solid #a1a1a1;
    }
    @media screen and (max-width: 1280px) {
        width: 32px;
        height: 32px;
        // margin-top: 8px;
        text-align: center;
        img {
            margin: 1px 0px 0px -3px;
        }
    }
}

.new-mini-fab-button {
    width: 32px;
    height: 32px;
    border-radius: 50%;
    border: 0;
    cursor: pointer;
    color: white;
    &.primary{
        background-color: #2196f3;
    }
    &.danger{
        background-color: #ef5350;
    }
    &.neutral {
        background-color: rgb(238, 234, 234);
        color: black;
    }
}

.options-box {
    width: 100%;
    height: 42px;
    margin: 4px 0px 4px 0px;
    padding: 0px 8px;
    display: flex;
    align-items: center;
    justify-content:space-between;
    border: 1px solid #EBEBEB;
    margin-bottom: 8px; 
}

.order-item-header{
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding: 12px 8px;
    background-color: rgb(238, 234, 234);
    span {
        font-size: 16px;
        font-weight: bold;
        font-family: 'Roboto', sans-serif;
    }
    &.space-between {
        justify-content: space-between !important;
    }
    &.blue {
        background-color: #2196f3;
        color: white;
    }
}

:root {
    --dashboard-blue-color: #3899e9;
   --dashboard-blue-light-3-color: #5fb2f6;
    --dashboard-blue-light-2-color: #8eccff;
     --dashboard-blue-light-1-color: #b2dcff;
     --dashboard-blue-light-0-color: #cde9ff;

    --dashboard-gray-color: #495057;
    --dashboard-gray-4-color: #6C757D;
    --dashboard-gray-3-color: #ADB5BD;
    --dashboard-gray-2-color: #CED4DA;
    --dashboard-gray-1-color: #DEE2E6;

    --dashboard-red-color: #f6bdb9;
    --dashboard-orange-color: #f7c88e;
    --dashboard-yellow-color: #fbe4af;
    --dashboard-green-color: #90be6d;
}